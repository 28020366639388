<template>
    <v-container fluid no-gutters class="mx-0 px-0 default-cursor">
        <v-row>
            <v-col cols="6" md="2" class="px-0">
                <v-card class="custom-date py-2">
                    <span>Πρόγραμμα/Αποτελέσματα</span>
                </v-card>
            </v-col>
        </v-row>
        <v-card v-if="matches.filter(element => element.pending).length === 0" class="mt-3 py-5">
            <v-row align="center" justify="center">
                <v-col cols="12" align="center">
                    <span>Δεν υπάρχουν διαθέσιμες επόμενες αγωνιστηκές.</span>
                </v-col>
            </v-row>
        </v-card>
        <v-row v-else style="overflow-y: hidden;">
            <v-col cols="12">
                <v-carousel
                    v-if="$vuetify.breakpoint.mobile"
                    height="auto"
                    hide-delimiters
                    :show-arrows="false"
                    @touchstart.native="onTouchStart"
                    @touchend.native="onTouchEnd"
                >
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn
                            icon
                            color="transparent"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-large color="orange"> mdi-chevron-left </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn
                            icon
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-large color="orange"> mdi-chevron-right </v-icon>
                        </v-btn>
                    </template>
                    <v-carousel-item
                        v-for="(match, index) in matches.filter(element => element.pending).sort((a, b) => { a.round > b.round ? 1 : -1})"
                        :key="index"
                        :eager="true"
                        style="touch-action: pan-y !important;"
                    >
                        <v-row justify="center" align="center">
                            <v-col
                                cols="12"
                                @click="!match.postponed && !match.pending ? $router.push({ name: 'Match', params: { id: match.id } }) : null"
                            >
                                <v-card class="elevation-1 rounded-lg py-3 px-3" style="min-height: 200px;">
                                    <v-row justify="center" align="center">
                                        <v-col v-if="match.postponed" cols="12" align="center" class="pb-0 mb-0">
                                            <v-icon
                                                color="red"
                                            >
                                                mdi-update
                                            </v-icon>
                                            Αναβληθέν παιχνίδι
                                        </v-col>
                                        <v-col cols="12" align="center">
                                            <v-icon color="orange"> mdi-basketball </v-icon>
                                            <span class="font-weight-bold"> Round {{ match.round }}, </span>
                                            <span class="font-weight-thin">
                                                {{ `${match.league ? match.league.title : ''}, ` + (match.cup? 'Κύπελλο' : (match.phase === 'regular_season' ? 'Regular Season' : (match.phase === 'placements' ? 'Προκριματικά' : 'Play Offs'))) }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="!match.postponed" class="py-0 my-0">
                                        <v-col cols="12" align="center" class="py-0 my-0 secondary-font font-weight-thin">
                                            <v-icon
                                                color="orange"
                                            >
                                                mdi-calendar-outline
                                            </v-icon>
                                            {{ getDate(match.matchDatetime) }}
                                        </v-col>
                                    </v-row>
                                    <v-row align="center" class="my-0 secondary-font">
                                        <v-col :cols="match.pending ? 4 : 3" @click.stop="goToTeam(match.team1)">
                                            <v-img
                                                v-if="match.team1.logo"
                                                :src="`${assetsUrl}/${match.team1.logo}`"
                                                height="50px"
                                                contain
                                            />
                                            <v-icon v-else style="font-size: 30px;" class="orange--text"> mdi-basketball </v-icon>
                                        </v-col>
                                        <v-col v-if="match.pending" cols="4" align="center" class="grey--text lighten-1 font-weight-bold px-0 rounded">
                                            <v-row no-guitters>
                                                <v-col cols="4" class="px-0 mx-0">
                                                    <v-img
                                                        :src="require('@/assets/jersey-orange.svg')"
                                                        height="30px"
                                                        contain
                                                    />
                                                </v-col>
                                                <v-col cols="4" class="px-0 mx-0">
                                                    <span class="mx-1">vs</span>
                                                </v-col>
                                                <v-col cols="4" class="px-0 mx-0">
                                                    <v-img
                                                        :src="require('@/assets/jersey-black.png')"
                                                        height="30px"
                                                        contain
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-if="!match.pending" cols="3" class="font-weight-bold left-rounded" :class="match.scoreTeam1 > match.scoreTeam2 ? 'winning-score custom-orange' : 'losing-score-left'">
                                            <span>{{ match.scoreTeam1 }}</span>
                                        </v-col>
                                        <v-col v-if="!match.pending" cols="3" class="font-weight-bold right-rounded" :class="match.scoreTeam2 > match.scoreTeam1 ? 'winning-score custom-orange' : 'losing-score-right'">
                                            <span>{{ match.scoreTeam2 }}</span>
                                        </v-col>
                                        <v-col :cols="match.pending ? 4 : 3" @click.stop="goToTeam(match.team2)">
                                            <v-img
                                                v-if="match.team2.logo"
                                                :src="`${assetsUrl}/${match.team2.logo}`"
                                                height="50px"
                                                contain
                                            />
                                            <v-icon v-else style="font-size: 30px;" class="orange--text"> mdi-basketball </v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters @click.stop="openMap(match.arena)">
                                        <v-col cols="12">
                                            <v-icon
                                                color="orange"
                                            >
                                                mdi-basketball-hoop-outline
                                            </v-icon>
                                            {{ match.arena?.name }}
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
                <v-carousel
                    v-else
                    hide-delimiters
                    height="auto"
                    show-arrows
                >
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn
                            icon
                            color="transparent"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-large color="orange"> mdi-chevron-left </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn
                            icon
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-large color="orange"> mdi-chevron-right </v-icon>
                        </v-btn>
                    </template>
                    <v-carousel-item
                        v-for="(group, groupIndex) in formatMatchArray()"
                        :key="groupIndex"
                        :eager="true"
                    >
                        <v-row justify="center" align="center">
                            <v-col
                                v-for="(match, index) in group"
                                :key="index"
                                cols="3"
                                class="clickable"
                                @click="!match.postponed && !match.pending ? $router.push({ name: 'Match', params: { id: match.id } }) : null"
                            >
                                <v-card class="elevation-1 rounded-lg py-3 px-3" style="min-height: 200px;">
                                    <v-row>
                                        <v-col v-if="match.postponed" cols="12" align="center" class="pb-0 mb-0">
                                            <v-icon
                                                color="red"
                                            >
                                                mdi-update
                                            </v-icon>
                                            Αναβληθέν παιχνίδι
                                        </v-col>
                                        <v-col cols="12" align="center">
                                            <v-icon color="orange"> mdi-basketball </v-icon>
                                            <span class="font-weight-bold"> Round {{ match.round }}, </span>
                                            <span class="font-weight-thin">
                                                {{ `${match.league ? match.league.title : ''}, ` + (match.cup? 'Κύπελλο' : (match.phase === 'regular_season' ? 'Regular Season' : (match.phase === 'placements' ? 'Προκριματικά' : 'Play Offs'))) }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="!match.postponed" class="py-0 my-0">
                                        <v-col cols="12" align="center" class="py-0 my-0 secondary-font font-weight-thin">
                                            <v-icon
                                                color="orange"
                                            >
                                                mdi-calendar-outline
                                            </v-icon>
                                            {{ getDate(match.matchDatetime) }}
                                        </v-col>
                                    </v-row>
                                    <v-row align="center" class="my-0 secondary-font">
                                        <v-col cols="4" @click.stop="goToTeam(match.team1)">
                                            <v-img
                                                v-if="match.team1.logo"
                                                :src="`${assetsUrl}/${match.team1.logo}`"
                                                height="50px"
                                                contain
                                            />
                                            <v-icon v-else style="font-size: 50px;" class="orange--text"> mdi-basketball </v-icon>
                                        </v-col>
                                        <v-col v-if="match.pending || match.postponed" cols="4" align="center" class="grey--text lighten-1 font-weight-bold px-0 rounded">
                                            <v-row no-guitters>
                                                <v-col cols="4" class="px-0 mx-0">
                                                    <v-img
                                                        :src="require('@/assets/jersey-orange.svg')"
                                                        height="30px"
                                                        contain
                                                    />
                                                </v-col>
                                                <v-col cols="4" class="px-0 mx-0">
                                                    <span class="mx-1">vs</span>
                                                </v-col>
                                                <v-col cols="4" class="px-0 mx-0">
                                                    <v-img
                                                        :src="require('@/assets/jersey-black.png')"
                                                        height="30px"
                                                        contain
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-if="!match.pending && !match.postponed" cols="2" class="font-weight-bold left-rounded" :class="match.scoreTeam1 > match.scoreTeam2 ? 'winning-score custom-orange' : 'losing-score-left'">
                                            <span>{{ match.scoreTeam1 }}</span>
                                        </v-col>
                                        <v-col v-if="!match.pending && !match.postponed" cols="2" class="font-weight-bold right-rounded" :class="match.scoreTeam2 > match.scoreTeam1 ? 'winning-score custom-orange' : 'losing-score-right'">
                                            <span>{{ match.scoreTeam2 }}</span>
                                        </v-col>
                                        <v-col cols="4" @click.stop="goToTeam(match.team2)">
                                            <v-img
                                                v-if="match.team2.logo"
                                                :src="`${assetsUrl}/${match.team2.logo}`"
                                                height="50px"
                                                contain
                                            />
                                            <v-icon v-else style="font-size: 50px;" class="orange--text"> mdi-basketball </v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters @click.stop="openMap(match.arena)">
                                        <v-col cols="12">
                                            <v-icon
                                                color="orange"
                                            >
                                                mdi-basketball-hoop-outline
                                            </v-icon>
                                            {{ match.arena?.name }}
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
        <results-team :matches="matches" />
    </v-container>
</template>

<script>
    import momentTimezone from 'moment-timezone'
    import ResultsTeam from '@/components/ResultsCarousel'
    export default {
        components: { ResultsTeam },
        props: {
            matches: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
                assetsUrl: 'https://storage.googleapis.com'
            }
        },
        created() {
            // this.$nextTick(() => { this.getMatchesAsc() })
        },
        methods: {
            // getMatchesAsc() {
            //     this.matches.sort(function(a, b) {
            //         if (momentTimezone(a.matchDatetime).isAfter(momentTimezone(b.matchDatetime))) {
            //             return 1
            //         } else {
            //             return -1
            //         }
            //     })
            // },
            getDate(date) {
                return momentTimezone(date).tz('Europe/Athens').locale('el-GR').format('dddd DD/MM/y, HH:mm').toString()
            },
            formatMatchArray() {
                const data = []
                let index = 1
                let temp = []
                this.matches.forEach(element => {
                    temp.push(element)
                    if (index % 4 === 0 || index === this.matches.length) {
                        data.push(temp)
                        temp = []
                    }
                    index++
                })
                return data
            },
            openMap(arena) {
                window.open(`https://www.google.com/maps/place/${arena.coordinates}`, '_self', 'noreferrer')
            },
            goToTeam(team) {
                this.$router.push({ name: 'Team', params: { id: team.id } })
            },
            onTouchStart() {
                document.querySelector('body').style.overflow = 'hidden'
            },
            onTouchEnd() {
                document.querySelector('body').style.overflow = 'auto'
            }
        }
    }
</script>

<style>
.v-window__next,
.v-window__prev {
    background: rgba(0, 0, 0, 0);
    margin: 0 -5px;
}

.left-rounded {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.right-rounded {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>

<style scoped>

.default-cursor {
    cursor: default;
}

.custom-orange {
    background: linear-gradient(90deg, #ef4444 0%, #ff7133 50%, orange 100%);
}

.losing-score-right {
    outline: solid 2px orange;
    outline-offset: -2px;
    color: black;
    background-color: white;
}

.losing-score-left {
    outline: solid 2px #ef4444;
    outline-offset: -2px;
    color: black;
    background-color: white;
}

.winning-score {
    color: white;
}

.custom-date {
    font-weight: 700;
    font-size: 1rem;

    /* border-style: solid; */
    border-radius: 5px;
    background: linear-gradient(90deg, #ef4444 0%, #ff7133 50%, orange 100%);
    color: white;
}
</style>
