import { render, staticRenderFns } from "./ResultsCarousel.vue?vue&type=template&id=37ce8880&scoped=true&"
import script from "./ResultsCarousel.vue?vue&type=script&lang=js&"
export * from "./ResultsCarousel.vue?vue&type=script&lang=js&"
import style0 from "./ResultsCarousel.vue?vue&type=style&index=0&id=37ce8880&prod&lang=css&"
import style1 from "./ResultsCarousel.vue?vue&type=style&index=1&id=37ce8880&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ce8880",
  null
  
)

export default component.exports